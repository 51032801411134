import * as React from 'react';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { UNSAFE_DataRouterStateContext, useParams } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { fetchCredentials } from './api/fetchCredentials';
import { fetchWebChatConfiguration } from './api/fetchWebChatConfiguration';
import { fetchPreloadUserByData } from './api/fetchPreloadUserByData';
import { fetchActiveMessageMatch } from './api/fetchWelcomeMessage';
import { fetchIpAddress } from './api/fetchIpAddress';
import { fetchDealer } from './api/fetchDealer';
import { postHubSpotContactNote } from './api/postHubSpotContactNote';

import * as postTrackWebchatViews from './api/postTrackWebchatViews';
import { postTrackUser } from './api/postTrackUser';
import './App.css';
import AppState from './AppState';
import { postTypingNotificationMessage } from './api/postTypingNotificationMessage';
import { ChatWindow } from './components/ChatWindow/ChatWindow';
import { ChatWelcome } from './components/ChatWelcome/ChatWelcome';
import UserProfile from './models/UserProfile';
import AuthData from './models/AuthData';
import { UserProfileType } from './models/UserProfileType';
import { WebChatThemeMode } from './models/WebChatThemeMode';
import LanguageText from './models/LanguageText';
import WebChatConfigurationEx from './models/WebChatConfigurationEx';
import ActiveMessageMatch from './models/ActiveMessageMatch';
import i18next from 'i18next';
import WebchatLocale from './models/Locale';
import themes from './theme.json';
import { initializeGATag, gtagConfig, gtagEvent } from './ga/gtag';

const AuthWindow = lazy(() => import('./components/AuthWindow/AuthWindow'));
const AuthWindowSMSSmall = lazy(() => import('./components/AuthWindowSMSSmall/AuthWindowSMSSmall'));
const AuthWindowDeptSmall = lazy(() => import('./components/AuthWindowDeptSmall/AuthWindowDeptSmall'));
const SmsDialog = lazy(() => import('./components/SmsDialog/SmsDialog'));
const BuyWindow = lazy(() => import('./components/BuyWindow/BuyWindow'));

initializeGATag('G-L13Z70FYY8', '', '');

const App: React.FC = () => {
	const { dealerId } = useParams<{ dealerId: string}>();
	const [state, setState] = useState<AppState> ({
		mobile: false,
		activeMessageMatch: null,
		smsDialogOpen: false,
		hasEngaged: false,
		expandMode: false,
		userTyped: false,
		themeName: null,
		themeNameSelected: false,
		dispatchCalled: false,
		authData: null,
		webchatConnecting: false,
		preloadUserChange: false,
		shouldHideChat: false,
		hasPreloadQuery: false,
		chatOpened: false,
		webChatLoaded: false,
		getButton: '',
		getHelp: false,
		userMessageSent: false,
		selectedVINForPurchase: '',
		paymentContactInfo: {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: ''
		},
		paymentRes: null,
		manualResetWebchatDialogOpen: false,
		siteUrl: '',
		ipAddress: '',
		userAgent: '',
		smsSet: false,
		waitingForBotReply: false,
		cid: '',
		currentTime: '',
		store: null,
		metadata: null,
		gaClientId: null,
		gaSessionId: null,
		pixelClickId: null,
		pixelBrowserId: null,
	});

    const welcomeNodeRef = useRef<HTMLDivElement>(null);
    const [authError, setAuthError] = useState(false);
    const [lastTimeTyping, setLastTimeTyping] = useState<number | null>(null);
    const lastTimeTypingThreshold = 1000;
    const [clickedActiveMessage, setClickedActiveMessage] = useState(false);
    const [authenticatedTimeout, setAuthenticatedTimeout] = useState<any>(null);
    const [scrollToBottomTimeout, setScrollToBottomTimeout] = useState<any>(null);
    const [waitingForBotReplyTimeout, setWaitingForBotReplyTimeout] = useState<any>(null);
	// private inactiveTimeout: any;

	const changeText: LanguageText = {
		continue: 'CONTINUE WITH:',
		login: 'OR CHAT WITHOUT LOG IN',
		sales: 'CHAT ABOUT SALES',
		parts: 'CHAT ABOUT PARTS',
		services: 'CHAT ABOUT SERVICES',
		loginWithInfo: 'BEGIN CHAT'
	};

	useEffect(() => {
		var currentTime = new Date();
		var currentTimeHour = currentTime.getHours();
		var currentHour = currentTimeHour.toString();
		var currentTimeMinute = currentTime.getMinutes();
		var currentMinute = currentTimeMinute.toString();

		if (currentTimeHour < 10)
		{
			currentHour = "0" + currentTimeHour.toString();
		}

		if (currentTimeMinute < 10)
		{
			currentMinute = "0" + currentTimeMinute.toString();
		}

		setState(prevState => ({
            ...prevState,
            currentTime: `${currentHour}:${currentMinute}`
        }));

		setAuthError(false);
		// postWelcomePopupClosedMessage = postWelcomePopupClosedMessage.bind(this);

		gtagConfig('G-L13Z70FYY8', 'dimension1', dealerId);

		if (window.parent != null) {
			window.addEventListener('message', onReceiveMessageFromParent);
		}

		// Check for updates to user's preferred color scheme, using https://stackoverflow.com/a/59621903
		const lightModePreference = window.matchMedia('(prefers-color-scheme: light)');
		const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
		lightModePreference.addEventListener('change', (e) => e.matches && activateLightMode());
		darkModePreference.addEventListener('change', (e) => e.matches && activateDarkMode());

		getSettings(dealerId);

		return () => {
			clearInterval(authenticatedTimeout);
		}
	}, []);

	// const stateRef = useRef(state);
	const callbackRef = useRef<(() => void)[]>([]);
	const stateRef = useRef<AppState>(state);

	useEffect(() => {
		stateRef.current = state;
		while(callbackRef.current.length > 0) {
			callbackRef.current[0]();
			callbackRef.current.shift();
		}
	}, [state]);

	const pushStateCallback = (callback: () => void) => {
		callbackRef.current.push(callback);
	}

	const activateLightMode = () => {
		if (state.config === null || state.config === undefined) {
			onChangeTheme('light', false);
		} else if (
			!(state.config === null || state.config === undefined) &&
			state.config.themeMode === WebChatThemeMode.Auto &&
			!state.themeNameSelected
		) {
			onChangeTheme('light', false);
		}
	};

	const activateDarkMode = () => {
		if (state.config === null || state.config === undefined) {
			onChangeTheme('dark', false);
		} else if (
			!(state.config === null || state.config === undefined) &&
			state.config.themeMode === WebChatThemeMode.Auto &&
			!state.themeNameSelected
		) {
			onChangeTheme('dark', false);
		}
	};

	const raiseEvent = (event, payload) => {
		window.parent.postMessage({ sender: 'ChatWindow', event: 'apievent', payload: { event, data: JSON.parse(JSON.stringify(payload))} }, '*');
	};

	const renderAuthWindow= (config, authWindowStyle) => {
		if (authWindowStyle) {
			switch (authWindowStyle) {
				case 3:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindowSMSSmall
								text={changeText}
								themeName={state.themeName}
								onChangeTheme={(themeName) => onChangeTheme(themeName)}
								configuration={config}
								onAuthenticated={onAuthWindowAuthenticated}
								onClose={() => persistState({ opened: false })}
								smsSet={state.smsSet}
							/>
						</Suspense>
					);
				case 4:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindowDeptSmall
								text={changeText}
								themeName={state.themeName}
								onChangeTheme={(themeName) => onChangeTheme(themeName)}
								configuration={config}
								onAuthenticated={onAuthWindowAuthenticated}
								onClose={() => persistState({ opened: false })}
								smsSet={state.smsSet}
							/>
						</Suspense>
					);
				case 0:
				default:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindow
								text={changeText}
								configuration={config}
								onAuthenticated={onAuthWindowAuthenticated}
								onClose={() => persistState({ opened: false })}
							/>
						</Suspense>
					);
			}
		}
		return (
			<Suspense fallback={<div></div>}>
				<AuthWindow
					text={changeText}
					configuration={config}
					onAuthenticated={onAuthWindowAuthenticated}
					onClose={() => persistState({ opened: false })}
				/>
			</Suspense>
		);
	};

	const renderBuyWindowLazy = () => {
		if (state.buyPopupOpened && state.selectedVINForPurchase) {
			return (
				<Suspense fallback={<div></div>}>
					<BuyWindow
						themeName={state.themeName}
						dealerId={dealerId}
						conversationId={state.token.conversationId}
						vin={state.selectedVINForPurchase}
						paymentRes={state.paymentRes}
						contactInfo={state.paymentContactInfo}
						setContactInfo={(val) => persistState({ paymentContactInfo: val })}
						onClose={() =>
							persistState({ buyPopupOpened: false, paymentRes: null, selectedVINForPurchase: null })
						}
						setPaymentSuccess={setPaymentSuccess}
					/>
				</Suspense>
			);
		}

		return <></>;
	};

	const renderSMSDialog = (config) => {
		if (state.smsDialogOpen) {
			return (
				<Suspense fallback={<div></div>}>
					<SmsDialog
						themeName={state.themeName}
						open={state.smsDialogOpen}
						onClose={() => {
							setState(prevState => ({
								...prevState,
								smsDialogOpen: false
							}));
							postSMSOpened(false);
						}}
						dealerId={state.config.dealerId}
						color={config.accentColor}
						onSendSMS={sendDealerGAEvent}
					/>
				</Suspense>
			);
		}
		return <></>;
	};

	const resetWebChat = () => {
		var currentTime = new Date();
		var currentTimeHour = currentTime.getHours();
		var currentHour = currentTimeHour.toString();
		var currentTimeMinute = currentTime.getMinutes();
		var currentMinute = currentTimeMinute.toString();

		if (currentTimeHour < 10)
		{
			currentHour = "0" + currentTimeHour.toString();
		}

		if (currentTimeMinute < 10)
		{
			currentMinute = "0" + currentTimeMinute.toString();
		}

		setState(prevState => ({
			...prevState,
			smsDialogOpen: false,
			hasEngaged: false,
			userTyped: false,
			dispatchCalled: false,
			chatOpened: false,
			webChatLoaded: false,
			token: undefined,
			manualResetWebchatDialogOpen: false,
			currentTime: `${currentHour}:${currentMinute}`,
			store: null,
		}));

		postHasEngaged(false);
		postUserTyped(false);
		postDispatchCalled(false);
		postMessage('Token', null);
		persistState({
			hasEngaged: false,
			userTyped: false,
			dispatchCalled: false,
			token: null
		});

		clearInterval(authenticatedTimeout);

		fetchDirectlineToken();
	};

	const fetchDirectlineToken = () => {
		if (state.config.mode === 1) {
			// need to validate name and email and preload user info is invalid
			if (state.config.isPrivate) {
				// authenticate only when has valid user info
				if (!state.shouldHideChat) {
					// when reset, mode = 1 && isPrivate = true && user info from query is valid, then reset
					// this user info to newly created chat again
					onAuthenticated(state.userProfile);
				}
			} else {
				// work as original
				onAuthenticated(null);
			}
		} else {
			// if not mode one, then keep it as unauthenticated
			postAuthenticatedMessage(false);
		}
    };

	const handleChatOpened = (chatOpened) => {
		setState(prevState => ({ ...prevState, chatOpened: chatOpened }));
	};

	const handleWebChatLoaded = () => {
		setState(prevState => ({ ...prevState, webChatLoaded: true }));
	};

	const handleUpdateGetHelp = (getHelp) => {
		if (!state.chatOpened) {
			handleChatOpened(true);
		}
		setState(prevState => ({ ...prevState, getHelp: getHelp }));
	};

	const handleUpdateGetButton = (getButton) => {
		if (!state.chatOpened) {
			handleChatOpened(true);
		}
		setState(prevState => ({ ...prevState, getButton: getButton }));
	};

	const setStore = (store: any) => {
		setState(prevState => ({ ...prevState, store: store }));
	};

	const onChangeLocale = (language) => {
		i18next.changeLanguage(language.botFrameworkLocale).then(() => {
			persistState({ language: language });
		});
	};

	const onChangeTheme = (themeName, themeSelected = true) => {
		persistState({ themeName: themeName });
		setThemeState({ themeName: themeName });
		if (themeSelected) {
			onChangeThemeSelected(true);
		}
	};

	const onChangeThemeSelected = (themeNameSelected) => {
		persistState({ themeNameSelected: themeNameSelected });
	};

	const onDispatchCalled = (dispatchCalled) => {
		persistState({ dispatchCalled: dispatchCalled });
	};

	const directLineConnect = (dispatchCalled, preloadUserChange) => {
		persistState({
			dispatchCalled: dispatchCalled,
			preloadUserChange: preloadUserChange
		});
	};

	const onUserMessage = (hasEngaged) => {
		persistState({ hasEngaged: hasEngaged });
	};

	const setVINForPurchase = (vin) => {
		persistState({
			selectedVINForPurchase: vin
		});
	};

	const setPaymentSuccess = (vin, paymentId) => {
		persistState({
			paymentRes: {
				vin,
				paymentId
			}
		});
	}

	const handleManualResetWebchatDialogOpen = (manualResetWebchatDialogOpen) => {
		setState(prevState => ({
			...prevState,
			manualResetWebchatDialogOpen: manualResetWebchatDialogOpen
		}));
	}

	const onClickChatWelcomeMessage = () => {
		setClickedActiveMessage(true);
		persistState({ opened: true, welcomeOpened: false });
		postWelcomePopupClosedMessage();
		postPopupMessage(true);
	};

	const persistState = <K extends keyof AppState>(_state: Pick<AppState, K>, callback?: () => void) => {
		setState(prevState => ({...prevState, ..._state}));
		pushStateCallback(() => {
			const state = stateRef.current;
			let settings: any = {};

			if (state.opened !== undefined) {
				settings.opened = state.opened;
			}
			if (state.welcomeOpened !== undefined) {
				settings.welcomeOpened = state.welcomeOpened;
			}
			if (state.config !== undefined) {
				settings.config = state.config;
			}
			if (state.userId !== undefined) {
				settings.userId = state.userId;
			}
			if (state.userProfile !== undefined) {
				settings.userProfile = state.userProfile;
			}
			if (state.token !== undefined) {
				settings.token = state.token;
			}
			if (state.authWindowStyle !== undefined) {
				settings.authWindowStyle = state.authWindowStyle;
			}
			if (state.language !== undefined) {
				settings.language = state.language;
			}
			if (state.themeName !== undefined) {
				settings.themeName = state.themeName;
			}
			if (state.themeNameSelected !== undefined) {
				settings.themeNameSelected = state.themeNameSelected;
			}
			if (state.hasEngaged !== undefined) {
				settings.hasEngaged = state.hasEngaged;
			}
			if (state.userTyped !== undefined) {
				settings.userTyped = state.userTyped;
			}
			if (state.dispatchCalled !== undefined) {
				settings.dispatchCalled = state.dispatchCalled;
			}
			if (state.authData !== undefined) {
				settings.authData = state.authData;
			}

			setSettings(dealerId, settings);

			if (callback) {
				callback();
			}
		});
	};

	const setThemeState = <K extends keyof AppState>(_state: Pick<AppState, K>, callback?: () => void) => {
		setState(prevState => ({...prevState, ..._state}));
		pushStateCallback(() => {
			let themeSetting: any = {};

			if (stateRef.current.themeName !== undefined) {
				themeSetting.themeName = stateRef.current.themeName;
			}

			setThemeSetting(dealerId, themeSetting);

			if (callback) {
				callback();
			}
		});
	};

	const setThemeSetting = (dealerId: string, themeSetting: any) => {
		postMessage('SetThemeSetting', {
			dealerId: dealerId,
			themeSetting: themeSetting
		});
	};

	const setSettings = (dealerId: string, settings: any) => {
		postMessage('SetSettings', {
			dealerId: dealerId,
			settings: settings
		});
	};

	const getSettings = (dealerId: string) => {
		postMessage('GetThemeSetting', { dealerId: dealerId });
		postMessage('GetSettings', { dealerId: dealerId });
	};

	const postMessage = (event: string, payload: any) => {
		window.parent.postMessage({ sender: 'ChatWindow', event: event, payload: payload }, '*');
	};

	const postPopupMessage = (open: boolean) => {
		postMessage('Popup', open);

		// GA - closing popup
		gtagEvent('Interaction', open ? 'Open Popup from Welcome' : 'Close Popup', 'G-L13Z70FYY8');
	};

	const postHasEngaged = (hasEngaged) => {
		postMessage('HasEngaged', hasEngaged);
	};

	const postUserTyped = (userTyped) => {
		postMessage('UserTyped', userTyped);
	};

	const postSMSOpened = (smsOpened) => {
		postMessage('SMSOpened', smsOpened);
	};

	const postDispatchCalled = (dispatchCalled) => {
		postMessage('DispatchCalled', dispatchCalled);
	};

	const postWelcomePopupClosedMessage = () => {
		postMessage('WelcomePopup', false);
	};

	const postInitialization = (
		config: WebChatConfigurationEx,
		shouldHideChat: boolean,
		hasPreloadQuery: boolean,
		authenticated: boolean,
		opened: boolean,
		welcomeOpened: boolean,
		authWindowStyle: number,
		hasActiveMessage: boolean,
		activeMessageMatch: ActiveMessageMatch,
		hasEngaged: boolean,
		userTyped: boolean,
		dispatchCalled: boolean
	) =>  {
		postMessage('Initialization', {
			config: config,
			shouldHideChat: shouldHideChat,
			hasPreloadQuery: hasPreloadQuery,
			authenticated: authenticated,
			opened: opened,
			welcomeOpened: welcomeOpened,
			authWindowStyle: authWindowStyle,
			hasActiveMessage: hasActiveMessage,
			activeMessageMatch: activeMessageMatch,
			hasEngaged: hasEngaged,
			userTyped: userTyped,
			dispatchCalled: dispatchCalled
		});
	};

	const postAuthenticatedMessage = (authenticated: boolean) => {
		postMessage('Authenticated', authenticated);
	};

	const postWelcomeHeightMessage = (height: any) => {
		postMessage('WelcomeHeight', height);
	};

	const onReceivedSettingsFromParent = async (payload: any) => {
		let {
			config,
			token,
			authWindowStyle,
			language,
			themeName,
			themeNameSelected,
			dispatchCalled,
			userProfile,
			preloadUserChange,
			shouldHideChat
		} = stateRef.current;

		// get authenticated status
		let authenticated = token !== undefined && token !== null && token.conversationId !== undefined;

		// if config.mode === 1, then authenticate based on condition
		if (config.mode === 1) {
			// 1. user first reach this page, then authenticate, create a bot user and chat
			// 2. previous preload user NOT match current preload user when config.isPrivate is true, then user change,
			// then authenticate, create new bot user and new chat
			// preloadUserChange can only be true when config.isPrivate is true
			if (!authenticated || preloadUserChange) {
				// need to validate name and email and preload user info is invalid
				if (config.isPrivate) {
					// authenticate only when has valid user info
					if (!shouldHideChat) {
						onAuthenticated(userProfile);
					}
				} else {
					// work as original
					onAuthenticated(null);
				}
			}
		}

		// pre-set locale
		if (!config.supportedLocales) {
			// supported locale list is empty, then always be english
			language = new WebchatLocale();
			language.code = 'en';
			language.botFrameworkLocale = 'en-US';
		} else if (language === undefined || !config.supportedLocales.some((t) => t.code === language.code)) {
			// no state language is set or no supported locales match the state language
			let fullBrowserLanguage = window.navigator.language;
			let partialBrowserLanguage = window.navigator.language.split('-')[0]; // en, es

			let matchedLocale = null;

			for (let locale of config.supportedLocales) {
				if (fullBrowserLanguage === locale.code) {
					matchedLocale = locale;
					break;
				} else if (partialBrowserLanguage === locale.code) {
					matchedLocale = locale;
				}
			}

			if (matchedLocale === null) {
				language = config.supportedLocales[0]; // en-US, es
			} else {
				language = matchedLocale;
			}
		}

		// pre-set theme
		if (themeName === undefined || themeName === null) {
			// if no themeName (set in cookies)
			let themePicked = 'light'; // set default theme to light

			// reset theme based on webchat configuration
			if (config.themeMode === WebChatThemeMode.Auto) {
				// config.themeMode default is auto
				if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
					themePicked = 'dark';
				} // else use light
			} else if (config.themeMode === WebChatThemeMode.Dark) {
				themePicked = 'dark';
			} // else then set to light

			// assign theme
			themeName = themePicked;
			// if there was no themeName set in cookies, themeNameSelected is false
			themeNameSelected = false;
		} // if has themeName (from cookies), then take that one as priority

		// pre-set dispatch call
		if (dispatchCalled === undefined || dispatchCalled === null) {
			dispatchCalled = false;
		}

		// track webchat views -- every info is ready, then track page view
		let isUniqueVisitor = Object.keys(payload).length === 0 ? true : false;
		await postTrackWebchatViews.postTrackViews(config.dealerId, `${authWindowStyle}`, isUniqueVisitor);

		// GA Setup
		gtagConfig('G-L13Z70FYY8', 'dimension2', '' + config.mode + '-' + authWindowStyle);

		// change language and persist state
		i18next.changeLanguage(language.botFrameworkLocale).then(async () => {
			persistState(
				{
					config: config,
					authWindowStyle: authWindowStyle,
					language: language,
					themeName: themeName,
					themeNameSelected: themeNameSelected,
					dispatchCalled: dispatchCalled
				},
				() => {
					postMessage('ParentUrl', null);
				}
			);
			setThemeState({ themeName: themeName });
		});

		let dealer = await fetchDealer(dealerId);
		persistState({ smsSet: dealer.sms !== undefined && dealer.sms !== null && dealer.sms !== ''});

		persistState({ buttonsList: [] });
	};

	const onReceiveMessageFromParent = async (e: MessageEvent) => {
		const _state = stateRef.current;
		const { event, payload } = e.data;

		if (event === 'GetThemeSetting') {
			// console.debug('retrieved theme setting in chat window', payload);
			if (payload.themeName !== _state.themeName) {
				setState(prevState => ({
					...prevState,
					themeName:
						payload.themeName !== null && payload.themeName !== undefined
							? payload.themeName
							: _state.themeName
				}));
			}
		} else if (event === 'GetSettings') {
			// console.debug('retrieved settings in chat window', payload);
			setState(prevState => ({ ...prevState, siteUrl: payload.currentUrl.replace(/\/$/, '') }));
			var ipAddress = await fetchIpAddress();
			setState(prevState => ({ ...prevState, ipAddress: ipAddress, userAgent: navigator.userAgent }));

			// settings from cookies
			let settingsPayload = payload.settings;
			let { config, token, authWindowStyle, userProfile } = settingsPayload; // from cookie if have
			let { preloadUserChange, shouldHideChat, hasPreloadQuery } = _state; // from state

			// move below part from onReceivedSettingsFromParent to here
			// can set info to state earlier before directline connection
			// e.g. for this : preloadUserChange
			// get config is not set in cookies
			if (config === undefined) {
				config = await fetchWebChatConfiguration(dealerId);
			}

			if (payload.overridePrivate) {
				config.isPrivate = false;
				config.isBlackListUrls = false;
				config.isWhiteListUrls = false;
			}

			// define authWindowStyle
			if (authWindowStyle === undefined) {
				if (config.authWindowStyles != null && config.authWindowStyles.length > 0) {
					authWindowStyle = config.authWindowStyles[0];
				} else {
					authWindowStyle = 0;
				}
			}

			// get preload user
			// set this: preloadUserChange, earlier
			// if mode is 1
			if (config.mode === 1) {
				// define auth window
				authWindowStyle = 0;

				// if this flag is true, then we check name, email, phone
				if (config.isPrivate) {
					// get preloadUser by data
					let preloadUser = await fetchPreloadUserByData(dealerId, payload.name, payload.email, payload.phone);
					// define has query flag
					hasPreloadQuery = preloadUser.hasPreloadQuery;

					// update userProfile - phone number is not always given
					if (preloadUser.preloadUserEmail && preloadUser.preloadUserName) {
						userProfile = new UserProfile();
						userProfile.email = preloadUser.preloadUserEmail;
						userProfile.name = preloadUser.preloadUserName;
						if (preloadUser.preloadUserPhone) {
							userProfile.phone = preloadUser.preloadUserPhone;
						}
						userProfile.type = UserProfileType.Preload;

						// record valid query
						userProfile.preloadQuery = preloadUser.preloadUserQuery;

						// do not hide chat window
						shouldHideChat = false;

						// both state and current preload users have value (both valid preload users), but not match
						// compare with one from state (which is from cookie)
						// preload user change, not the previous user
						if (settingsPayload.userProfile) {
							if (
								settingsPayload.userProfile.email !== userProfile.email ||
								settingsPayload.userProfile.name !== userProfile.name ||
								settingsPayload.userProfile.phone !== userProfile.phone ||
								settingsPayload.userProfile.preloadQuery !== userProfile.preloadQuery
							) {
								preloadUserChange = true;

								// if preload user change (for config.mode === 1 only), set token to be undefined, need to re-authenticate
								token = undefined;
								postAuthenticatedMessage(false);
							}
						}
					} else {
						// hide chat window
						shouldHideChat = true;
					}

					// if hubspot id is set, set it in state (only if isPrivate is true)
					if (payload.cid) {
						setState(prevState => ({ ...prevState, cid: payload.cid }));
					}
				} // otherwise it works as original
			}

			// set state
			setState(prevState =>
			({
				...prevState,
				authWindowStyle: authWindowStyle,
				config: config,
				hasEngaged: settingsPayload.hasEngaged ? settingsPayload.hasEngaged : false,
				userTyped: settingsPayload.userTyped ? settingsPayload.userTyped : false,
				language: settingsPayload.language,
				opened: _state.mobile ? false : settingsPayload.opened,
				themeName:
					_state.themeName === 'light' || _state.themeName === 'dark'
						? _state.themeName
						: settingsPayload.themeName,
				themeNameSelected: _state.themeNameSelected || settingsPayload.themeNameSelected ? true : false,
				token: token,
				userId: settingsPayload.userId,
				welcomeOpened: settingsPayload.welcomeOpened,
				dispatchCalled: settingsPayload.dispatchCalled,
				authData: settingsPayload.authData,
				userProfile: userProfile,
				preloadUserChange: preloadUserChange,
				shouldHideChat: shouldHideChat,
				hasPreloadQuery: hasPreloadQuery
			})
			);
			pushStateCallback(() => { onReceivedSettingsFromParent(payload); });

		} else if (event === 'Popup') {
			// console.debug('Received popup message: ' + payload);
			if (payload) {
				const isActiveMessage = _state.activeMessageMatch !== null;

				if (isActiveMessage && !clickedActiveMessage && _state.token === undefined) {
					onClickChatWelcomeMessage();
				}

				persistState({ opened: true });
			} else {
				persistState({ opened: false });
			}
		} else if (event === 'WelcomePopup') {
			if (payload) {
				persistState({ welcomeOpened: true });
			} else {
				persistState({ welcomeOpened: false });
			}
		} else if (event === 'HasEngaged') {
			onUserMessage(payload);
		} else if (event === 'ExpandMode') {
			setState(prevState => ({ ...prevState, expandMode: payload }));
		} else if (event === 'DispatchCalled') {
			onDispatchCalled(payload);
		} else if (event === 'Mobile') {
			setState(prevState => ({ ...prevState, mobile: payload }));
			if (payload) {
				setState(prevState => ({ ...prevState, opened: false }));
			}
		} else if (event === 'ParentUrl') {
			const { url, vin } = payload;
			try {
				let activeMessageMatch: ActiveMessageMatch | null = null;
				try {
					if (_state.config.mode === 1 && _state.authWindowStyle === 0) {
						activeMessageMatch = await fetchActiveMessageMatch(
							_state.config.dealerId,
							url,
							vin,
							_state.language.botFrameworkLocale.split('-')[0]
						);
					}
				} catch (e) { }
				setState(prevState => ({ ...prevState, activeMessageMatch: activeMessageMatch }));
				postInitialization(
					_state.config,
					_state.shouldHideChat,
					_state.hasPreloadQuery,
					_state.token !== undefined &&
					_state.token !== null &&
					_state.token.conversationId !== undefined,
					_state.opened,
					_state.welcomeOpened,
					_state.authWindowStyle,
					activeMessageMatch != null,
					activeMessageMatch,
					_state.hasEngaged,
					_state.userTyped,
					_state.dispatchCalled
				);
			} catch (e) {
				console.error(e);
			}
		} else if (event === 'api.send' || event === 'api.message') {
			if (!_state.chatOpened) {
				persistState({ opened: true });
				window.parent.postMessage({ sender: 'ChatWindow', event: 'Popup', payload: true }, '*');
				window.postMessage({ sender: 'parent', event: 'api.sendinternal', payload: payload }, '*');
			}
		} else if (event === 'api.openchat') {
			window.parent.postMessage({ sender: 'ChatWindow', event: 'Popup', payload: true }, '*');
		} else if (event === 'api.closechat') {
			persistState({
				chatOpened: false,
				opened: false
			});
		} else if (event === 'GAClientId') {
			// payload
			console.debug('GA Ids: ', payload);
			setState(prevState => ({
				...prevState,
				gaClientId: payload.gaClientId,
				gaSessionId: payload.gaSessionId
			}));
		} else if (event === 'PixelParams') {
			// pixel params
			console.debug('Pixel Params: ', payload);
			setState(prevState => ({
				...prevState,
				pixelClickId: payload.fbc,
				pixelBrowerId: payload.fbp
			}));
		}
		 
	};

	const onAuthWindowAuthenticated = async (userProfile: UserProfile | null, authData?: AuthData) => {
		onAuthenticated(userProfile, authData);
		setState(prevState => ({ ...prevState, hasEngaged: true }));
		postHasEngaged(true);
	};

	const onAuthenticated = async (userProfile: UserProfile | null, authData?: AuthData) => {
		// authentication will always treat as new user
		console.debug('onAuthenticated', userProfile);
		let credentials = await fetchCredentials(dealerId, /*this.state.userId ? this.state.userId :*/ null);
		setAuthError(false);

		// set userId, userProfile and token in state
		persistState({
			userId: credentials.userId,
			userProfile: userProfile,
			token: credentials.directLineToken,
			authData: authData
		}, () => {
			const state = stateRef.current;
			postAuthenticatedMessage(true);

			if (userProfile) {
				let stringType = '';
				if (userProfile.type === UserProfileType.Preload) {
					stringType = 'Preload';
				} else if (userProfile.type === UserProfileType.FacebookWebchat) {
					stringType = 'Facebook';
				} else if (userProfile.type === UserProfileType.Apple) {
					stringType = 'Apple';
				} else {
					stringType = 'Google';
				}

				gtagConfig('G-L13Z70FYY8', 'user_id', credentials.userId);
				gtagEvent('User', 'Auth - ' + stringType, 'G-L13Z70FYY8');
			} else {
				gtagConfig('G-L13Z70FYY8', 'user_id', credentials.userId);
				gtagEvent('User', 'Auth - Anonymous', 'G-L13Z70FYY8');
			}

			postTrackUser(
				dealerId,
				state.userId,
				state.token.conversationId,
				getParentUrl(),
				'auth',
				state.config.mode + '-' + (state.authWindowStyle ?? 0)
			);
		});
	};

	const onDispatchActivity = ({ dispatch }: any) => {
		return (next: any) => (action: any) => {
			if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
				setState(prevState => ({ ...prevState, webchatConnecting: true }));
				if (!state.dispatchCalled || state.preloadUserChange) {
					// This should only be called once at the start of the conversation
					dispatch({
						type: 'WEB_CHAT/SEND_EVENT',
						payload: {
							name: 'webchat/join',
							value: {
								userProfile: state.userProfile,
								activeMessageMatch: state.activeMessageMatch,
								browserLanguage: window.navigator.language,
								webchatLanguage: state.language.code,
								sendSupportedFeatures: state.config.mode === 1 ? 'false' : 'true',
								leadAction: state.authData ? state.authData.leadAction : null,
								browserTime: state.currentTime,
								metadata: state.metadata,
								gaClientId: state.gaClientId,
								gaSessionId: state.gaSessionId,
								pixelBrowserId: state.pixelBrowserId,
								pixelClickId: state.pixelClickId,
							}
						}
					});
					setState(prevState => ({ ...prevState, waitingForBotReply: true }));

					if (state.metadata) {
						setState(prevState => ({ ...prevState, hasEngaged: true }));
						postHasEngaged(true);
					}

					// First message
					sendDealerGAEvent('Interaction', 'dealerai_webchat_engaged');

					// If isPrivate is true and data-cid is set, send a note to hubspot when user first engages with webchat
					if (state.cid !== '') {
						postHubSpotContactNote(state.cid);
					}
				}
			} else if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {

				action.payload.activity.channelData = {
					dealerId: dealerId,
					browserLanguage: window.navigator.language,
					webchatLanguage: state.language.code,
					url: state.siteUrl,
					ipAddress: state.ipAddress,
					userAgent: state.userAgent,
					gaClientId: state.gaClientId,
					gaSessionId: state.gaSessionId,
					pixelBrowserId: state.pixelBrowserId,
					pixelClickId: state.pixelClickId,
				};

				setState(prevState => ({ ...prevState, waitingForBotReply: true }));
				setWaitingForBotReplyTimeout(setTimeout(() => {
					console.debug("Clear wait bot reply timeout");
					setState(prevState => ({ ...prevState, waitingForBotReply: false }));
				}, 120000));
			} else if (action.type === 'WEB_CHAT/SEND_MESSAGE') {
				// Clear timeouts
				//clearTimeout(this.inactiveTimeout);

				let lastTimeTyping = Date.now();
				if (
					lastTimeTyping == null ||
					lastTimeTyping - lastTimeTyping > lastTimeTypingThreshold
				) {
					lastTimeTyping = lastTimeTyping;
					postTypingNotificationMessage(
						state.config.portalBaseUrl,
						state.config.dealerId,
						state.token.conversationId,
						lastTimeTyping
					);
				}
				if (!state.hasEngaged) {
					postTrackUser(
						dealerId,
						state.userId,
						state.token.conversationId,
						getParentUrl(),
						'engaged',
						state.config.mode + '-' + (state.authWindowStyle ?? 0)
					);

					// track webchat views -- track conversation, first user message
					postTrackWebchatViews.postTrackConversations(dealerId, `${state.authWindowStyle ?? 0}`);
				}
				setState(prevState => ({ ...prevState, hasEngaged: true }));
				postHasEngaged(true);
				setState(prevState => ({ ...prevState, userMessageSent: true }));
				setState(prevState => ({ ...prevState, userTyped: true }));
				postUserTyped(true);

				raiseEvent('messageSent', action.payload);
			} else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
				if (action.payload.activity.type === "typing") {
					// Prevent sending the typing activity in order to show the bot avatar
					return null;
				}

				// conversation is in take over mode
				if (action.payload.activity.type === "handoff") {
					setState(prevState => ({ ...prevState, waitingForBotReply: false }));
					return null;
				}

				// GA event
				if (action.payload.activity.type === "dealerai_webchat_lead") {
					sendDealerGAEvent('Interaction', 'dealerai_webchat_lead');
					return null;
				}

				if (
					(!action.payload.activity.name || action.payload.activity.name !== 'inactive') &&
					(!action.payload.activity.text || action.payload.activity.text !== 'Hey, are you still there?')) {
					// Message sent by the bot
					/*clearTimeout(this.inactiveTimeout);
					this.inactiveTimeout = setTimeout(() => {
						console.log("Sending inactive event");
						// Notify bot the user has been inactive
						dispatch({
							type: 'WEB_CHAT/SEND_EVENT',
							payload: {
								name: 'inactive',
								value: {
									userProfile: this.state.userProfile,
									activeMessageMatch: this.clickedActiveMessage ? this.state.activeMessageMatch : null,
									browserLanguage: window.navigator.language,
									webchatLanguage: this.state.language.code,
								}
							}
						});
					}, 15000)*/
					if (!state.dispatchCalled) {
						directLineConnect(true, false);
						// onDispatchCalled(true);
						postDispatchCalled(true);
					}
					setState(prevState => ({ ...prevState, webchatConnecting: false }));
				}

				if (action.payload.activity.type === 'message'
					&& action.payload.activity.from && action.payload.activity.from.role === 'bot' 
					&& action.payload.activity.value !== 'DEBUGMESSAGE' && action.payload.activity.value !== 'WAITPROGRESS') {

						setState(prevState => ({ ...prevState, waitingForBotReply: false }));
					if (waitingForBotReplyTimeout) {
						clearTimeout(waitingForBotReplyTimeout);
					}
				}
				
				if (new Date(action.payload.activity.timestamp) > new Date()) {
					if (document.querySelector('section[role="feed"]') && document.querySelector('section[role="feed"]').lastElementChild) {
						if (scrollToBottomTimeout) {
							clearTimeout(scrollToBottomTimeout);
                        }
						setScrollToBottomTimeout(setTimeout(() => {
							console.debug('scroll to bottom');
							document.querySelector('section[role="feed"]').lastElementChild.scrollIntoView();
						}, 150));
					}
				}
				raiseEvent('messageReceived', action.payload);
			}
			return next(action);
		};
	};

	const getParentUrl = (): string => {
		var isInIframe = window.parent !== window,
			parentUrl = null;
		if (isInIframe) {
			parentUrl = document.referrer;
		}
		return parentUrl;
	};

	const onPopupAnimationCompleted = () => {
		postPopupMessage(false);
	};

	const onTokenRefreshed = (newToken: string) => {
		const expiry = (JSON.parse(atob(newToken.split('.')[1]))).exp * 1000;
		console.debug('token refresh - expiry', new Date(expiry));
		persistState({ token: { ...state.token, token: newToken, expiryDate: new Date(expiry)} });
	};
	
	const onConnectionStatusFailed = () => {
		persistState({ token: undefined });
	};

	const sendDealerGAEvent = (category: string, action: string) => {
		console.debug('ga event', category, action);
		if (state.config.customTracking !== undefined && state.config.customTracking.isEnabled !== undefined && state.config.customTracking.isEnabled === true && state.config.customTracking.gA4Id !== undefined && state.config.customTracking.gA4Id !== '') {
			
			window.parent.postMessage({ sender: 'ChatWindow', event: 'gaevent', payload: { category, action, trackingId: state.config.customTracking.gA4Id } }, '*');
		}
	};

	const onWelcomePopupEntering = () => {
		setTimeout(() => {
			// console.debug(welcomeNodeRef.current.offsetHeight);
			postMessage('WelcomePopupEntering', welcomeNodeRef.current.offsetHeight);
		}, 10)
	};
	
	const { opened, welcomeOpened, buyPopupOpened, userId, token, config, mobile, expandMode, authWindowStyle } = state;

	const isActiveMessage = state.activeMessageMatch !== null;
	const isAuthenticated =
		state.token !== undefined &&
		state.token !== null &&
		state.token.conversationId !== undefined;

	if (!config) {
		return null;
	}

	const classes = ['window-container'];

	if (mobile) {
		classes.push('mobile');
	}
	if (expandMode) {
		classes.push('expandMode');
	}

	// window container class name
	let windowContainerClassName = classes.join(' ');
	if (
		state.config.mode === 1 &&
		((state.config.isPrivate && state.shouldHideChat) || !isAuthenticated)
	) {
		windowContainerClassName = '';
	}

	let currentTheme =
		state.themeName !== null && state.themeName !== undefined ? state.themeName : 'light';

	let menuIconColor = themes[currentTheme]['webchatHeaderIconColor'];

	const containerStyles = {
		'--primary-color': config.accentColor,
		'--primary-color-50': config.accentColor + '80',
		'--primary-color-04': config.accentColor + '0A',
		'--primary-color-4f': config.accentColor + '4F',
		'--text-color': config.textColor === undefined || config.textColor === '' ? '#ffffff' : config.textColor,
		'justifyContent': 'end'
	} as React.CSSProperties;

	return (
		//<Suspense fallback={<div></div>}>
			<div className={`app theme-${state.themeName}`} style={containerStyles}>
				<CSSTransition
					nodeRef={welcomeNodeRef}
					in={welcomeOpened}
					classNames={'css-transition-' + (mobile ? (config.alignRight ? 'left' : 'right') : 'up')}
					timeout={state.expandMode ? 0 : 400}
					onEntering={onWelcomePopupEntering}
					exit
					mountOnEnter
					unmountOnExit={true}
				>
					<div ref={welcomeNodeRef}>
						<ChatWelcome
							message={
								isActiveMessage && state.activeMessageMatch.message !== ''
									? state.activeMessageMatch.message
									: state.config.welcomeMessageSecondary
							}
							mobile={mobile}
							configuration={config}
							onClick={onClickChatWelcomeMessage}
							onClose={() => {
								persistState({ welcomeOpened: false });
								postWelcomePopupClosedMessage();
							}}
						/>
					</div>
				</CSSTransition>
				<CSSTransition
					in={opened}
					classNames={'css-transition-up'}
					timeout={state.expandMode ? 0 : 400}
					onExited={onPopupAnimationCompleted}
				>
					<TransitionGroup className={windowContainerClassName} style={{ display: opened ? 'block' : 'none' }}>
						{state.config.mode === 1 ? (
							(!state.config.isPrivate || !state.shouldHideChat) && isAuthenticated ? (
								<CSSTransition classNames={'css-transition-left'} timeout={state.expandMode ? 0 : 400} mountOnEnter>
									<div className="chat-windows-container">
										<ChatWindow
											configuration={config}
											userId={userId}
											token={token}
											language={state.language}
											opened={state.opened}
											onClose={() => {
												persistState({
													opened: false
												});
											}}
											onBuyPopupOpen={() =>
												persistState({
													buyPopupOpened: true
												})
											}
											onLanguageChange={(language) => {
												onChangeLocale(language);
											}}
											onTokenRefreshed={onTokenRefreshed}
											dispatchCalled={state.dispatchCalled}
											onDispatchActivity={onDispatchActivity}
											showSmsButton={!state.hasEngaged}
											onClickSmsButton={() => {
												setState(prevState => ({
													...prevState,
													smsDialogOpen: true
												}));
												postSMSOpened(true);
											}}
											smsSet={state.smsSet}
											themeName={state.themeName}
											onChangeTheme={(themeName) => onChangeTheme(themeName)}
											color={menuIconColor}
											mobile={mobile}
											hasEngaged={state.hasEngaged}
											expandMode={state.expandMode}
											shouldHideChat={state.shouldHideChat}
											userProfile={state.userProfile}
											onConnectionStatusFailed={onConnectionStatusFailed}
											onAuthenticated={onAuthenticated}
											postAuthenticatedMessage={postAuthenticatedMessage}
											postWelcomeHeightMessage={postWelcomeHeightMessage}
											resetWebChat={resetWebChat}
											fetchDirectlineToken={fetchDirectlineToken}
											webchatConnecting={state.webchatConnecting}
											chatOpened={state.chatOpened}
											handleChatOpened={handleChatOpened}
											webChatLoaded={state.webChatLoaded}
											handleWebChatLoaded={handleWebChatLoaded}
											getHelp={state.getHelp}
											onHelp={handleUpdateGetHelp}
											getButton={state.getButton}
											onButton={handleUpdateGetButton}
											waitingForBotReply={state.waitingForBotReply}
											userMessageSent={state.userMessageSent}
											handleUserMessageSent={(userMessageSent) =>
												setState(prevState => ({
													...prevState,
													userMessageSent: userMessageSent
												}))
											}
											activeMessageMatch={state.activeMessageMatch}
											setVINForPurchase={setVINForPurchase}
											setPaymentSuccess={setPaymentSuccess}
											manualResetWebchatDialogOpen={state.manualResetWebchatDialogOpen}
											handleManualResetWebchatDialogOpen={handleManualResetWebchatDialogOpen}
											currentTime={state.currentTime}
											store={state.store}
											setStore={setStore}
											setMetadata={(metadata) =>
												setState(prevState => ({
													...prevState,
													metadata: metadata
												}))
											}
										/>
										{ renderSMSDialog(config)}
									</div>
								</CSSTransition>
							) : (
								<></>
							)
						) : !isAuthenticated || authError ? (
							<CSSTransition
								key="0"
								classNames={'css-transition-left'}
								timeout={400}
								mountOnEnter
								unmountOnExit
							>
								{renderAuthWindow(config, authWindowStyle)}
							</CSSTransition>
						) : (
							<CSSTransition key="1" classNames={'css-transition-left'} timeout={state.expandMode ? 0 : 400} mountOnEnter>
										<ChatWindow
										configuration={config}
										userId={userId}
										token={token}
										language={state.language}
										opened={state.opened}
										onClose={() => persistState({ opened: false })}
										onBuyPopupOpen={() =>
											persistState({
												buyPopupOpened: true
											})
										}
										onLanguageChange={(language) => {
											onChangeLocale(language);
										}}
										onTokenRefreshed={onTokenRefreshed}
										dispatchCalled={state.dispatchCalled}
										onDispatchActivity={() => onDispatchActivity}
										showSmsButton={false}
										onClickSmsButton={() => {
											setState(prevState => ({
												...prevState,
												smsDialogOpen: true
											}));
											postSMSOpened(true);
										}}
										smsSet={state.smsSet}
										themeName={state.themeName}
										onChangeTheme={(themeName) => onChangeTheme(themeName)}
										color={menuIconColor}
										mobile={mobile}
										hasEngaged={state.hasEngaged}
										expandMode={state.expandMode}
										shouldHideChat={state.shouldHideChat}
										userProfile={state.userProfile}
										onConnectionStatusFailed={onConnectionStatusFailed}
										onAuthenticated={onAuthenticated}
										postAuthenticatedMessage={postAuthenticatedMessage}
										postWelcomeHeightMessage={postWelcomeHeightMessage}
										resetWebChat={resetWebChat}
										fetchDirectlineToken={fetchDirectlineToken}
										webchatConnecting={state.webchatConnecting}
										chatOpened={state.chatOpened}
										handleChatOpened={handleChatOpened}
										webChatLoaded={state.webChatLoaded}
										handleWebChatLoaded={handleWebChatLoaded}
										getHelp={state.getHelp}
										onHelp={handleUpdateGetHelp}
										getButton={state.getButton}
										onButton={handleUpdateGetButton}
										waitingForBotReply={state.waitingForBotReply}
										userMessageSent={state.userMessageSent}
										handleUserMessageSent={(userMessageSent) =>
											setState(prevState => ({
												...prevState,
												userMessageSent: userMessageSent
											}))
										}
										activeMessageMatch={state.activeMessageMatch}
										setVINForPurchase={setVINForPurchase}
										setPaymentSuccess={setPaymentSuccess}
										manualResetWebchatDialogOpen={state.manualResetWebchatDialogOpen}
										handleManualResetWebchatDialogOpen={handleManualResetWebchatDialogOpen}
										currentTime={state.currentTime}
										store={state.store}
										setStore={setStore}
										setMetadata={(metadata) =>
											setState(prevState => ({
												...prevState,
												metadata: metadata
											}))
										}
									/>
								</CSSTransition>
						)}
					</TransitionGroup>
				</CSSTransition>
				<CSSTransition
					in={opened && buyPopupOpened}
					classNames={'css-transition-left'}
					timeout={400}
					mountOnEnter
				>
					{renderBuyWindowLazy()}
				</CSSTransition>
			</div>
		//</Suspense>
	);
}

export default App;